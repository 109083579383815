import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { questionnaireStarted, retakeQuestionnaire } from "../../../../store/actions/pg4dashboard";
import { updateLabUpload } from "../../../../store/actions/labUpload";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../methods";
import { ReactComponent as ClockIcon } from "../../../../assets/dashboard/clock.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/dashboard/check.svg";
import { ReactComponent as FileIcon } from "../../../../assets/dashboard/file.svg";
import { ReactComponent as TrendingIcon } from "../../../../assets/dashboard/trending-down.svg";
import { ExternalGeneticVendor } from "../ExternalGeneticVendor";
import React, { useState } from "react";

const AssignedCard = ({ cardInfo, context }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const patient_id = useSelector(state => state.auth.currentUserId);
    const currentPractice = useSelector(state => state.auth.currentPractice);
    const retakeQuestionnaireAction = (questionnaire_id) => dispatch(retakeQuestionnaire({ token, patient_id, currentPractice, questionnaire_id }));
    const questionnaireStartedAction = (session_id) => dispatch(questionnaireStarted({ token, patient_id, currentPractice, session_id }));
    const updateLabFileStatus = (data) => dispatch(updateLabUpload(data));
    const labFileStatus = useSelector(state => state.labUpload.fileStatus);
    const { t, i18n } = useTranslation();
    const [patientConsent, setPatientConsent] = useState(false);

    const goToLabUploadPage = () => {
        const isOldFile = ((cardInfo.id) && (cardInfo.status?.toLowerCase() !== "completed"));
        console.log('locstate:', cardInfo, isOldFile);
        if (isOldFile) {
            navigate('/patient/lab', { state: { isOldFile, dID: cardInfo.id, dIDs: cardInfo.document_ids } })
        } else {
            navigate('/patient/lab', { state: { isOldFile } })
        }
    }

    const navigateFn = async () => {
        console.log(cardInfo)
        if (cardInfo.card_type === "genetic_upload") {
            navigate("/patient/snp")
        }
        else if (cardInfo.card_type === "blood_work") {
            goToLabUploadPage();
        }
        else if (cardInfo.card_type === "genetic_nordic_card"){
            // setPatientConsent(true) might be needed when DNA life version2 changes needed
            window.open(process.env.REACT_APP_NORDIC_URL, "_blank")
        }
        else if (cardInfo.card_type === "questionnaire_msq" || cardInfo.card_type === "questionnaire_avq") {
            if (cardInfo?.qpro_questionnaire_id){
                const response = await questionnaireStartedAction(cardInfo.session_id);
                navigate("/patient/qpro_questionnaire", { state: cardInfo })
            } else{
                console.log("qpro_con")
            }
        }
    }
    return (<div className="pg4-card position-relative overflow-hidden dashboard-card bg-light h-100 my-3" >
        {cardInfo?.showInfoBanner && <div className="position-absolute p-3" style={{ transform: 'rotate(130deg)', top: -67, right: -55, height: 120, width: 120, background: 'var(--pg_primary__p)' }}>
            <div className="position-relative">
                <i className="position-absolute far fa-star fs-4" style={{ transform: 'rotate(158deg)', top: '30px', right: "-8px", color: '#fff' }}></i>
            </div>
        </div>}
        <div className="d-flex flex-column justify-content-between h-100">
            <div>
                <div className="pg4-text__heading text_primary" >{cardInfo.heading}</div>
                <div className="my-2 pg4-text my-3 fw-semibold">
                    <ClockIcon /> <span className="my-auto ms-2 text_primary text-uppercase text_primary fw-semibold"> {cardInfo.timimg_data}</span>
                    <div className="pg4-text my-2" dangerouslySetInnerHTML={{ __html: cardInfo.html_content }} />
                </div>
            </div>
            <button
                className="pg4-btn pg4-btn_primary w-sm-100 mx-0 mx-lg-5"
                onClick={navigateFn}
            >
                {cardInfo.button_text}
            </button>
            {(cardInfo.card_type === "genetic_upload" && cardInfo.status == "completed") &&
                <button className="pg4-btn pd-btn w-sm-100 mx-0 mx-lg-5" onClick={navigateFn}> {t('pracpl_btn_2')} </button>
            }
        </div>
        {patientConsent && <ExternalGeneticVendor patientConsent= {patientConsent} setPatientConsent= {setPatientConsent}/>} 
    </div>);
}

export default AssignedCard;